<template>
  <v-container fluid>
    <v-col class="legal-text">
      <h1>Privacy Policy</h1>
      <p>Latest update: May 2023</p>
      <p>Please read this Privacy Policy carefully as it contains important information on who we are and how and why we collect, store, use and share your Personal Data and which rights and options you have in this regard. Please see the relevant headings below for more information on each of these areas.
      </p>
      <p>Please also use the Glossary within this document to understand the meaning of some of the terms that are used.</p>
      <p>This Privacy Policy is issued on behalf of World Mobile Group Ltd, so when we mention “we”, “us” or “our” in this Privacy Policy, we are referring to the relevant company in the Group responsible for processing your data. We will let you know which entity will be the controller for your data when you purchase a product or service with us. World Mobile Group Ltd is the controller and responsible for this website. World Mobile Group Ltd, 29 Holywell Row, London EC2A 4JB, is a United Kingdom limited liability company, company number: 13191263, focused on international telecommunications. World Mobile Token BVI, Floor 4, Banco Popular Building, Road Town, Totola, VG1110, BVI, company number: 2057398, is a subsidiary of World Mobile Group and in data processing activities related to the Token it will act as a processor or a joint controller.</p>
      <h2>Contact Us</h2>
      <p>We have appointed a Data Protection Officer (DPO) who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the DPO using the details set out below.</p>
      <table class="ml-10">
        <tbody><tr>
          <td>
            <strong>Data Protection Officer:</strong>
          </td>
          <td>
            Enrique Opi
          </td>
        </tr>
        <tr>
          <td>
            <strong>Office:</strong>
          </td>
          <td>
            29 Holywell Row, London EC2A 4JB
          </td>
        </tr>
        <tr>
          <td>
            <strong>Email:</strong>
          </td>
          <td>
            <a href="mailto:dataprotection@worldmobiletoken.com">dataprotection@worldmobiletoken.com</a>
          </td>
        </tr>
      </tbody></table>
      <p>
        You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
      </p>
      <hr>
      <h2>Content</h2>
          <ul class="nav__list">
            <li class="nav__item">
              <a class="nav__link" href="#section-1">
                Important information and who we are
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-2">
                The data we collect about you
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-3">
                How we use your personal data
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-4">
                Disclosures of your personal data
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-5">
                International transfers
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-6">
                Data security
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-7">
                Data retention
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-8">
                Your legal rights
              </a>
            </li>
            <li class="nav__item">
              <a class="nav__link" href="#section-9">
                Glossary
              </a>
            </li>
          </ul>
      <hr>
      <h2 id="section-1">Important information and who we are</h2>
      <p>Purpose of this privacy policy</p>This Privacy Policy aims to give you information on how World Mobile Group Ltd collects and processes your personal data through your use of this website, including any data you may provide through this website when you purchase a product or service.<p></p>
      <p>It is important that you read this Privacy Policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This Privacy Policy supplements other notices and privacy policies and is not intended to override them.</p>
      <p>Controller</p>
      <p>World Mobile Group Ltd, 29 Holywell Row, London EC2A 4JB, is a United Kingdom limited liability company, company number: 13191263, focused on international telecommunications. World Mobile Group Ltd will be responsible for your Personal Data.</p>
      <p>Your duty to inform us of changes</p>
      <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
      <p>Third-party links</p>
      <p>This website may include links to third-party websites, plug-ins and applications. Clicking on these links or enabling these connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
      <h2 id="section-2">The data we collect about you</h2>
      <p>We may collect personal information from you in the course of our business, including through your use of our website, when you contact or request information from us, when you engage our services or as a result of your relationship with one or more of our staff.</p>
      <p>The personal information that we process includes:</p>
      <ul>
        <li><strong>Identity Data</strong> includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</li>
        <li><strong>Contact Data</strong> includes billing address, delivery address, email address and telephone numbers.</li>
        <li><strong>Financial Data</strong> includes bank account and payment card details.</li>
        <li><strong>Transaction Data</strong> includes details about payments to and from you and other details of products and services you have purchased from us.</li>
        <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
        <li><strong>Profile Data</strong> includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
        <li><strong>Usage Data</strong> includes information about how you use our website, products and services.</li>
        <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
      </ul>
      <p>This Personal Data is required to enable us to provide our service to you. If you do not provide personal data we ask for, it may delay or prevent us from providing services to you.</p>
      <p>We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy Policy.</p>
      <p>We do not collect any <strong>Special Categories of Personal Data</strong> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
      <p>If you fail to provide personal data</p>
      <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
      <h2 id="section-3">How we use your personal data</h2>
      <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
      <p>Where we need to perform the contract we are about to enter into or have entered into with you. Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests. Where we need to comply with a legal obligation. Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.</p>
      <p>Purposes for which we will use your personal data</p>
      <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
      <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data.</p>
      <p>Whether we receive your Personal Data directly from you or from a third-party source, we will only use your Personal Data if we have a proper reason for doing so, e.g.: for the performance of our contract with you or to take steps at your request before entering into a contract; for our legitimate interests or those of any third party recipients that receive your Personal Data; or where you have given consent. We may use your Personal Data for the following purposes only (“<strong>Permitted Purposes</strong>”):</p>
      <ul>
        <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
        <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
        <li>Where we need to comply with a legal obligation.</li>
        <li>For any purpose related and/or ancillary to any of the above or any other purposes for which your Personal Data was provided to us.</li>
      </ul>
      <p>Marketing</p>
      <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</p>
      <p>You will receive marketing communications from us if you have requested information from us or purchased goods or services from us and you have not opted out of receiving that marketing. We have a legitimate interest in processing your Personal Data for marketing purposes. This means we do not usually need your consent to send you marketing communications. However, where consent is needed, we will ask for this consent separately and clearly. We will also comply with your request to stop sending any such further communications.</p>
      <p>We will always treat your Personal Data with the utmost respect and never sell or share it with other organisations outside the World Mobile Group for marketing purposes except with your prior permission. You have the right to opt out of receiving marketing communications at any time by emailing Enrique Opi, Data Protection Officer, at <a href="mailto:dataprotection@worldmobiletoken.com">dataprotection@worldmobiletoken.com</a>.
      </p>
      <p>Opting out</p>
      <p>You can ask us or third parties to stop sending you marketing messages at any time by contacting us at any time. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>
      <p>Cookies</p>
      <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</p>
      <p>Change of purpose</p>
      <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
      <h2 id="section-4">Disclosures of your personal data</h2>
      <p>We may share your personal information with certain trusted third parties in accordance with contractual arrangements in place with them, including:</p>
      <ul>
        <li>Our professional advisers.</li>
        <li>Subcontractors and suppliers to whom we outsource certain services.</li>
        <li>Our insurers and brokers.</li>
        <li>Our banks.</li>
        <li>Courts, law enforcement agencies and regulatory bodies to comply with our legal and regulatory obligations. We will use reasonable endeavours to notify you before we do this, unless we are legally restricted from doing so.</li>
        <li>Third parties involved in hosting or organising events or seminars.</li>
        <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</li>
      </ul>
      <p>Where we share or transfer your Personal Data, we will do this in accordance with applicable data protection laws and will take appropriate safeguards to ensure its integrity and protection.</p>
      <h2 id="section-5">International transfers</h2>
      <p>We use partners for Know Your Customer (KYC) purposes. Their servers are located inside the European Economic Area (EEA) and therefore your data is processed within the European Economic Area (EEA).</p>
      <p>Whenever we transfer your personal data out of the European Economic Area (EEA), we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented: </p>
      <p>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data. </p>
      <p>Where we use certain service providers, we may use specific contracts approved for use in the European Economic Area (EEA) which give personal data the same protection it has in the United Kingdom. </p>
      <p>Whenever possible we will avoid transferring your personal data to any geographical regions outside the European Economic Area (EEA). Personal data collected outside the European Economic Area (EEA) will be processed locally.  </p>
      <p>Please contact us if you want further information on the specific mechanism used by us should we need to transfer your personal data out of the European Economic Area (EEA) for exceptional reasons. </p>
      <h2 id="section-6">Data security</h2>
      <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
      <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
      <h2 id="section-7">Data retention</h2>
      <p>How long will you use my personal data for?</p>
      <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
      <p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
      <p>By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers.</p>
      <p>In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
      <h2 id="section-8">Your legal rights</h2>
      <p>In addition to your rights under applicable data protection legislation and where we are permitted or required by applicable law and regulation, we will provide you, upon request, with a copy of your Personal Data and we will correct any errors identified by you. Subject to certain legal conditions, you also have the right to have any inaccurate Personal Data corrected and to object to or restrict our using your Personal Data.</p>
      <p>We may request that you prove your identity by providing us with a copy of a valid means of identification in order for us to comply with our security obligations and to prevent unauthorised disclosure of data. We reserve the right to charge you a reasonable administrative fee for any manifestly unfounded or excessive requests concerning your access to your data, and for any additional copies of the Personal Data you request from us.</p>
      <p>All such requests, including any requests to update Personal Data about you or any questions or comments regarding this policy or our handling of your Personal Data, should be addressed to Enrique Opi, Data Protection Officer, at <a href="mailto:dataprotection@worldmobiletoken.com">dataprotection@worldmobiletoken.com</a>.</p>
      <p>You have the following rights:</p>
      <p><strong>Request access</strong> to your personal data (commonly known as a “<strong>data subject access request</strong>“). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
      <p><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
      <p><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
      <p><strong>Object to processing</strong></p>
      <p>of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
      <p><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</p>
      <ul>
        <li>If you want us to establish the data’s accuracy.</li>
        <li>Where our use of the data is unlawful but you do not want us to erase it.</li>
        <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</li>
        <li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
      </ul>
      <p><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>
      <p><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
      <p>Please note that even after you have chosen to withdraw your consent we may be able to continue to process your personal information to the extent required or otherwise permitted by law, in particular in connection with exercising and defending our legal rights or meeting our legal and regulatory obligations.</p>
      <p>If you would like to exercise any of these rights, please advise us by emailing Enrique Opi, Data Protection Officer, at <a href="mailto:dataprotection@worldmobiletoken.com">dataprotection@worldmobiletoken.com</a> We may request that you prove your identity by providing us with a copy of a valid means of identification in order for us to comply with our security obligations and to prevent unauthorised disclosure of data.</p>
      <p>No fee usually required</p>
      <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
      <p>What we may need from you</p>
      <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
      <p>Time limit to respond</p>
      <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
      <h2 id="section-9">Glossary</h2>
      <p>LAWFUL BASIS</p>
      <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
      <p><strong>Performance of Contract</strong> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
      <p><strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</p>
      <p>THIRD PARTIES</p>
      <p>Internal Third Parties</p>
      <p>Other companies in the World Mobile Group Ltd acting as joint controllers or processors and who are based both inside and outside of the European Economic Area (EEA) and provide IT and system administration services and undertake leadership reporting.</p>
      <p>External Third Parties</p>
      <p>Service providers acting as processors based inside and outside of the European Economic Area (EEA) who provide IT and system administration services. </p>
      <p>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based inside and outside of the European Economic Area (EEA) who provide consultancy, banking, legal, insurance and accounting services. </p>
      <p>HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom or any other country in the European Economic Area (EEA) who require reporting of processing activities in certain circumstances. </p>
    </v-col>
    <v-btn
      color="primary darken-1 white--text back-button"
      elevation="2"
      @click="$router.push('/')"
    >
      Go back
    </v-btn>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.back-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
</style>
